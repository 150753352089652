import defaultConfig from '../../config/configDefault';

const menuItems = [
  { pageName: 'LandingPage', translationId: 'General.home' },
  { pageName: 'AboutPage', translationId: 'General.aboutUs' },
  { pageName: 'ContactPage', translationId: 'General.contactUs' },
  { href: defaultConfig.husqvarnaUrl, translationId: 'General.husqvarnaWebsite' },
];

export default menuItems;
