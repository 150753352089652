import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { getCurrentLocale } from '../../util/locale';
import { loadData as SearchPageLoader } from '../SearchPage/SearchPage.duck';

export const ASSET_NAME = 'landing-page';

export const loadData = (...args) => dispatch => {
  const urlLocale = getCurrentLocale();

  if (urlLocale) {
    return dispatch(SearchPageLoader(...args)(dispatch));
  }

  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };
  return dispatch(fetchPageAssets(pageAsset, true));
};
