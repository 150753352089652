import React, { useCallback } from 'react';
import Button from '../Button/Button';
import { useIntl } from 'react-intl';
import { bool, func, node, number, object, shape, string } from 'prop-types';
import { propTypes } from '../../util/types';
import classNames from 'classnames';

import css from './LoadMore.module.css';

const PROP_TYPES = {
  className: string,
  inProgress: bool,
  loadMoreMsg: node,
  /** A func. like this `(nextPage: number) => void` */
  loadMoreFn: func.isRequired,
  pagination: propTypes.pagination.isRequired,
};

const LoadMoreButton = props => {
  const { className, inProgress, loadMore, loadMoreMsg } = props;

  const intl = useIntl();

  return (
    <Button
      type="button"
      className={classNames(css.root, className)}
      disabled={inProgress}
      inProgress={inProgress}
      onClick={loadMore}
    >
      {loadMoreMsg || intl.formatMessage({ id: 'General.loadMore' })}
    </Button>
  );
};

export const LoadMoreWithKey = props => {
  const { pagination, loadMoreFn, loadedItemsCount, ...rest } = props;

  const { lastKey, totalItems } = pagination || {};

  const loadMore = useCallback(() => {
    loadMoreFn(lastKey);
  }, [lastKey, loadMoreFn]);

  if (!lastKey || totalItems === loadedItemsCount) return null;

  return <LoadMoreButton {...rest} loadMore={loadMore} />;
};

LoadMoreWithKey.propTypes = {
  ...PROP_TYPES,
  loadedItemsCount: number,
  /** A func. like this `(lastKey: object) => void` */
  loadMoreFn: func.isRequired,
  pagination: shape({ lastKey: object, totalItems: number }),
};

const LoadMore = props => {
  const { loadMoreFn, pagination, ...rest } = props;

  const { page, totalPages } = pagination || {};

  const loadMore = useCallback(() => {
    const nextPage = page + 1;

    if (nextPage > totalPages) return;

    loadMoreFn(nextPage);
  }, [loadMoreFn, page, totalPages]);

  if (!pagination || page >= totalPages) return null;

  return <LoadMoreButton {...rest} loadMore={loadMore} />;
};

LoadMore.propTypes = PROP_TYPES;

export default LoadMore;
