import api from '../api';
import { LANGUAGE_TO_MARKET_NAME } from '../constants/dimr';
import { getLangFromUrl } from '../util/locale';

export const TDR_SEARCH_REQUEST = 'app/tdr/TDR_SEARCH_REQUEST';
export const TDR_SEARCH_SUCCESS = 'app/tdr/TDR_SEARCH_SUCCESS';
export const TDR_SEARCH_ERROR = 'app/tdr/TDR_SEARCH_ERROR';

export const TDR_SUGGESTIONS_REQUEST = 'app/tdr/TDR_SUGGESTIONS_REQUEST';
export const TDR_SUGGESTIONS_SUCCESS = 'app/tdr/TDR_SUGGESTIONS_SUCCESS';
export const TDR_SUGGESTIONS_ERROR = 'app/tdr/TDR_SUGGESTIONS_ERROR';

export const TDR_MATCHING_MODELS_REQUEST = 'app/tdr/TDR_MATCHING_MODELS_REQUEST';
export const TDR_MATCHING_MODELS_SUCCESS = 'app/tdr/TDR_MATCHING_MODELS_SUCCESS';
export const TDR_MATCHING_MODELS_ERROR = 'app/tdr/TDR_MATCHING_MODELS_ERROR';

export const TDR_SPARE_PART_LIST_REQUEST = 'app/tdr/TDR_SPARE_PART_LIST_REQUEST';
export const TDR_SPARE_PART_LIST_SUCCESS = 'app/tdr/TDR_SPARE_PART_LIST_SUCCESS';
export const TDR_SPARE_PART_LIST_ERROR = 'app/tdr/TDR_SPARE_PART_LIST_ERROR';

export const TDR_SPARE_PART_IMAGE_REQUEST = 'app/tdr/TDR_SPARE_PART_IMAGE_REQUEST';
export const TDR_SPARE_PART_IMAGE_SUCCESS = 'app/tdr/TDR_SPARE_PART_IMAGE_SUCCESS';
export const TDR_SPARE_PART_IMAGE_ERROR = 'app/tdr/TDR_SPARE_PART_IMAGE_ERROR';

// ================ Reducer ================ //

const initialState = {
  tdrSearchAttributes: [],
  tdrSearchInProgress: false,
  tdrSearchError: null,

  tdrSuggestions: [],
  tdrSuggestionsInProgress: false,
  tdrSuggestionsError: null,

  tdrMatchingModels: [],
  tdrMatchingModelsInProgress: false,
  tdrMatchingModelsError: null,

  tdrSparePartList: [],
  tdrSparePartListInProgress: false,
  tdrSparePartListError: null,

  tdrSparePartImageInProgress: false,
  tdrSparePartImageError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case TDR_SEARCH_REQUEST:
      return { ...state, tdrSearchInProgress: true, tdrSearchError: null };
    case TDR_SEARCH_SUCCESS:
      return { ...state, tdrSearchInProgress: false, tdrSearchAttributes: payload };
    case TDR_SEARCH_ERROR:
      return {
        ...state,
        tdrSearchInProgress: false,
        tdrSearchError: payload,
        tdrSearchAttributes: [],
      };

    case TDR_SUGGESTIONS_REQUEST:
      return { ...state, tdrSuggestionsInProgress: true, tdrSuggestionsError: null };
    case TDR_SUGGESTIONS_SUCCESS:
      return { ...state, tdrSuggestionsInProgress: false, tdrSuggestions: payload };
    case TDR_SUGGESTIONS_ERROR:
      return {
        ...state,
        tdrSuggestionsInProgress: false,
        tdrSuggestionsError: payload,
        tdrSuggestions: [],
      };

    case TDR_MATCHING_MODELS_REQUEST:
      return { ...state, tdrMatchingModelsInProgress: true, tdrMatchingModelsError: null };
    case TDR_MATCHING_MODELS_SUCCESS:
      return { ...state, tdrMatchingModelsInProgress: false, tdrMatchingModels: payload };
    case TDR_MATCHING_MODELS_ERROR:
      return {
        ...state,
        tdrMatchingModelsInProgress: false,
        tdrMatchingModelsError: payload,
        tdrMatchingModels: [],
      };

    case TDR_SPARE_PART_LIST_REQUEST:
      return { ...state, tdrSparePartListInProgress: true, tdrSparePartListError: null };
    case TDR_SPARE_PART_LIST_SUCCESS:
      return { ...state, tdrSparePartListInProgress: false, tdrSparePartList: payload };
    case TDR_SPARE_PART_LIST_ERROR:
      return {
        ...state,
        tdrSparePartListInProgress: false,
        tdrSparePartListError: payload,
        tdrSparePartList: [],
      };

    case TDR_SPARE_PART_IMAGE_REQUEST:
      return { ...state, tdrSparePartImageInProgress: true, tdrSparePartImageError: null };
    case TDR_SPARE_PART_IMAGE_SUCCESS:
      return { ...state, tdrSparePartImageInProgress: false };
    case TDR_SPARE_PART_IMAGE_ERROR:
      return {
        ...state,
        tdrSparePartImageInProgress: false,
        tdrSparePartImageError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const tdrSearchRequest = () => ({ type: TDR_SEARCH_REQUEST });
const tdrSearchSuccess = payload => ({ type: TDR_SEARCH_SUCCESS, payload });
const tdrSearchError = payload => ({ type: TDR_SEARCH_ERROR, payload });

const tdrSuggestionsRequest = () => ({ type: TDR_SUGGESTIONS_REQUEST });
const tdrSuggestionsSuccess = payload => ({ type: TDR_SUGGESTIONS_SUCCESS, payload });
const tdrSuggestionsError = payload => ({ type: TDR_SUGGESTIONS_ERROR, payload });

const tdrMatchingModelsRequest = () => ({ type: TDR_MATCHING_MODELS_REQUEST });
export const tdrMatchingModelsSuccess = payload => ({ type: TDR_MATCHING_MODELS_SUCCESS, payload });
const tdrMatchingModelsError = payload => ({ type: TDR_MATCHING_MODELS_ERROR, payload });

const tdrSparePartListRequest = () => ({ type: TDR_SPARE_PART_LIST_REQUEST });
export const tdrSparePartListSuccess = payload => ({ type: TDR_SPARE_PART_LIST_SUCCESS, payload });
const tdrSparePartListError = payload => ({ type: TDR_SPARE_PART_LIST_ERROR, payload });

const tdrSparePartImageRequest = () => ({ type: TDR_SPARE_PART_IMAGE_REQUEST });
const tdrSparePartImageSuccess = payload => ({ type: TDR_SPARE_PART_IMAGE_SUCCESS, payload });
const tdrSparePartImageError = payload => ({ type: TDR_SPARE_PART_IMAGE_ERROR, payload });

// ================ Thunks ================ //

export const getTDRSearch = (search, params = {}) => dispatch => {
  dispatch(tdrSearchRequest());

  const lang = getLangFromUrl();

  const marketName = LANGUAGE_TO_MARKET_NAME[lang];

  return api.listings
    .getTDRSearch({ search, marketName, ...params })
    .then(res => {
      dispatch(tdrSearchSuccess(res.data));

      return res;
    })
    .catch(e => {
      console.error(e);
      dispatch(tdrSearchError(e));
    });
};

export const getTDRSuggestions = (search, params = {}) => dispatch => {
  dispatch(tdrSuggestionsRequest());

  const lang = getLangFromUrl();

  const marketName = LANGUAGE_TO_MARKET_NAME[lang];

  return api.listings
    .getTDRSuggestions({ search, marketName, ...params })
    .then(res => {
      dispatch(tdrSuggestionsSuccess(res.data.productModels));

      return res;
    })
    .catch(e => {
      console.error(e);
      dispatch(tdrSuggestionsError(e));
    });
};

export const getTDRMatchingModels = (sparePartPnc, params = {}) => dispatch => {
  dispatch(tdrMatchingModelsRequest());

  const lang = getLangFromUrl();

  const marketName = LANGUAGE_TO_MARKET_NAME[lang];

  return api.listings
    .getTDRMatchingModels({ sparePartPnc, marketName, ...params })
    .then(res => {
      dispatch(tdrMatchingModelsSuccess(res.data));

      return res.data;
    })
    .catch(e => {
      console.error(e);
      dispatch(tdrMatchingModelsError(e));
    });
};

export const getTDRSparePartList = (pmid, cfsId, params = {}) => dispatch => {
  dispatch(tdrSparePartListRequest());

  const lang = getLangFromUrl();

  const marketName = LANGUAGE_TO_MARKET_NAME[lang];

  return api.listings
    .getTDRSparePartListOfModel({
      pmid,
      marketName,
      cfsId,
      ...params,
    })
    .then(res => {
      dispatch(tdrSparePartListSuccess(res.data));

      return res;
    })
    .catch(e => {
      console.error(e);
      dispatch(tdrSparePartListError(e));
    });
};

export const getTDRSparePartImage = (pmid, cfsId, pnc) => dispatch => {
  dispatch(tdrSparePartImageRequest());

  const lang = getLangFromUrl();

  const marketName = LANGUAGE_TO_MARKET_NAME[lang];

  return api.listings
    .getTDRSparePartListOfModel({
      pmid,
      marketName,
      cfsId,
    })
    .then(res => {
      let imageUrls;

      dispatch(tdrSparePartImageSuccess());

      res.data?.forEach(partList => {
        const { parts, schemaImageUrls } = partList;

        const hasFoundPart = parts?.some(partVariants =>
          partVariants?.some(variant => variant?.pnc === pnc)
        );

        if (hasFoundPart) {
          imageUrls = schemaImageUrls;

          return;
        }
      });

      return imageUrls;
    })
    .catch(e => {
      console.error(e);
      dispatch(tdrSparePartImageError(e));
    });
};
