import support from './support';
import listings from './listings';
import listingBulks from './listingBulks';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  support,
  listings,
  listingBulks,
};
