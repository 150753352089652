import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Menu from '../Menu/Menu';
import MenuLabel from '../MenuLabel/MenuLabel';
import MenuContent from '../MenuContent/MenuContent';
import MenuItem from '../MenuItem/MenuItem';
import classNames from 'classnames';
import { LANGUAGES_BY_LOCALE, getLabelForLang, getCurrentLocale } from '../../util/locale';
import useInterfaceLang from '../../hooks/useInterfaceLang';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { matchPathname } from '../../util/routes';
import NamedLink from '../NamedLink/NamedLink';

import css from './LanguageMenu.module.css';

const LanguageMenu = props => {
  const { className, useArrow } = props;

  const { interfaceLang, changeLanguage } = useInterfaceLang();

  const routeConfiguration = useRouteConfiguration();

  const location = useLocation();

  const currentLocale = getCurrentLocale();

  const classes = classNames(css.root, className);

  const matchedRoutes = useMemo(() => matchPathname(location.pathname, routeConfiguration), [
    location.pathname,
    routeConfiguration,
  ]);

  const foundRoute = matchedRoutes?.[0];

  if (!(LANGUAGES_BY_LOCALE[currentLocale]?.length > 1) || !foundRoute) return null;

  return (
    <Menu className={classes} useArrow={useArrow}>
      <MenuLabel showArrow className={css.menuLabel} isOpenClassName={css.menuIsOpen}>
        {interfaceLang ? interfaceLang.toUpperCase() : null}
      </MenuLabel>
      <MenuContent className={css.menuContent}>
        {LANGUAGES_BY_LOCALE[currentLocale].map(lang => (
          <MenuItem key={lang}>
            <NamedLink
              name={foundRoute.route.name}
              className={classNames(css.item, {
                [css.itemSelected]: interfaceLang === lang,
              })}
              onClick={e => {
                if (lang === interfaceLang) {
                  e.preventDefault();
                }

                changeLanguage(lang);
              }}
              params={{ ...foundRoute.params, lang }}
            >
              <span className={css.menuItemBorder} />
              {getLabelForLang(lang)}
            </NamedLink>
          </MenuItem>
        ))}
      </MenuContent>
    </Menu>
  );
};

export default LanguageMenu;
