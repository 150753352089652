import React, { useState } from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, node, string } from 'prop-types';
import classNames from 'classnames';
import {
  Avatar,
  InlineTextButton,
  ListingLink,
  ReviewRating,
  UserDisplayName,
} from '../../components';
import { propTypes } from '../../util/types';
import truncate from 'lodash/truncate';

import css from './Reviews.module.css';

// Approximated collapsed size so that there are ~three lines of text
// in the desktop layout in the author section of the ListingPage.
const BIO_COLLAPSED_LENGTH = 250;

const truncated = s => {
  return truncate(s, {
    length: BIO_COLLAPSED_LENGTH,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

const Review = props => {
  const { review, intl } = props;

  const [expand, setExpand] = useState(false);

  const date = review.attributes.createdAt;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });

  const truncatedContent = review.attributes.content ? truncated(review.attributes.content) : null;

  const handleShowMoreClick = () => {
    setExpand(true);
  };

  const showMore = (
    <InlineTextButton rootClassName={css.showMore} onClick={handleShowMoreClick}>
      {intl.formatMessage({ id: 'General.readMore' })}
    </InlineTextButton>
  );

  return (
    <div className={css.review}>
      <Avatar className={css.avatar} user={review.author} />
      <div>
        <ListingLink listing={review.listing} />
        <ReviewRating
          rating={review.attributes.rating}
          className={css.mobileReviewRating}
          reviewStarClassName={css.reviewRatingStar}
        />
        <p className={css.reviewContent}>
          {expand ? review.attributes.content : truncatedContent}
          {review.attributes.content !== truncatedContent && !expand ? showMore : null}
        </p>
        <p className={css.reviewInfo}>
          <UserDisplayName user={review.author} intl={intl} />
          <span className={css.separator}>•</span>
          {dateString}
          <span className={css.desktopSeparator}>•</span>
          <span className={css.desktopReviewRatingWrapper}>
            <ReviewRating
              rating={review.attributes.rating}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
          </span>
        </p>
      </div>
    </div>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
  const { className, rootClassName, reviews, intl, loadMoreNode } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <ul className={classes}>
      {reviews.length ? (
        reviews.map(r => {
          return (
            <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
              <Review review={r} intl={intl} />
            </li>
          );
        })
      ) : (
        <li>{intl.formatMessage({ id: 'General.noReviews' })}</li>
      )}

      {loadMoreNode ? <li>{loadMoreNode}</li> : null}
    </ul>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviews: arrayOf(propTypes.review),
  loadMoreNode: node,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
