import React from 'react';
import { useConfiguration } from '../../context/configurationContext';
import loadable from '@loadable/component';
import { useIntl } from 'react-intl';
import { matchLocale, matchTranslate } from '../../util/richText';

const SectionBuilder = loadable(
  () => import(/* webpackChunkName: "SectionBuilder" */ '../PageBuilder/PageBuilder'),
  {
    resolveComponent: components => components.SectionBuilder,
  }
);

const FooterComponent = () => {
  const { footer = {} } = useConfiguration();
  const intl = useIntl();

  // If footer asset is not set, let's not render Footer at all.
  if (Object.keys(footer).length === 0) {
    return null;
  }

  const { slogan, copyright, blocks, ...rest } = footer;

  // The footer asset does not specify sectionId or sectionType. However, the SectionBuilder
  // expects sectionId and sectionType in order to identify the section. We add those
  // attributes here before passing the asset to SectionBuilder.
  const footerSection = {
    ...rest,
    // Multi-language content is currently not supported through Flex-console.
    // Translations are handled manually here.
    slogan: {
      ...slogan,
      content: matchTranslate(slogan.content, intl),
    },
    copyright: {
      ...copyright,
      content: matchTranslate(copyright.content, intl),
    },
    blocks: blocks.map(block => ({
      ...block,
      text: {
        ...block.text,
        content: matchTranslate(matchLocale(block.text.content), intl),
      },
    })),
    sectionId: 'footer',
    sectionType: 'footer',
  };

  return <SectionBuilder sections={[footerSection]} />;
};

// NOTE: if you want to add dynamic data to FooterComponent,
//       you could just connect this FooterContainer to Redux Store
//
// const mapStateToProps = state => {
//   const { currentUser } = state.user;
//   return { currentUser };
// };
// const FooterContainer = compose(connect(mapStateToProps))(FooterComponent);
// export default FooterContainer;

export default FooterComponent;
