import httpClient from '../httpClient';

const getFilters = params => {
  return httpClient.get('listings/filters', { params });
};

const getComments = (listingId, params) => {
  return httpClient.get(`listings/${listingId}/comments`, { params });
};

const createComment = (listingId, comment, isFollowing) => {
  return httpClient.post(`listings/${listingId}/comments`, { comment, isFollowing });
};

const followCommentDiscussion = listingId => {
  return httpClient.post(`listings/${listingId}/comments/follow`);
};

const unfollowCommentDiscussion = listingId => {
  return httpClient.post(`listings/${listingId}/comments/unfollow`);
};

const deleteComment = (listingId, commentId) => {
  return httpClient.delete(`listings/${listingId}/comments/${commentId}`);
};

/**
 * @param {string | number} articleNumber 9 digit article number
 * @returns {Promise<{
 * key: string,
 * value: { unit: string, value: string } | { [lang]: string } | string
 * }[]>}
 */
const getDIMRAttributes = (articleNumber, params) => {
  return httpClient.get('listings/dimr-attributes', { params: { articleNumber, ...params } });
};

/**
 * @returns 
 * Something like
[
  {
    "displayName": "Accessories and tools",
    "children": [
      {
        "displayName": "Blower Attachment",
        "children": [
          {
            "displayName": "BA101",
            "children": []
          },
          {
            "displayName": "BAC",
            "children": []
          },
          {
            "displayName": "BRUSHCUTTER/TRIMMER ATTACHMENT",
            "children": [
              {
                "displayName": "BA101 - BLOWER ATTACHMENTS",
                "children": []
              },
              {
                "displayName": "BAC - LDX BLOWER ATTACHMENT",
                "children": []
              }
            ]
          }
        ]
      },
    ]
  }
]
 */
const getTDRCategories = params => {
  return httpClient.get('listings/tdr-categories', { params });
};

const getTDRSuggestions = params => {
  return httpClient.get('listings/tdr-suggestions', { params });
};

const getTDRSearch = params => {
  return httpClient.get('listings/tdr-search', { params });
};

const getTDRMatchingModels = params => {
  return httpClient.get('listings/tdr-matching-models', { params });
};

const getTDRSparePartListOfModel = params => {
  return httpClient.get('listings/tdr-part-list', { params });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getFilters,
  getComments,
  createComment,
  deleteComment,
  followCommentDiscussion,
  unfollowCommentDiscussion,
  getDIMRAttributes,
  getTDRCategories,
  getTDRSuggestions,
  getTDRSearch,
  getTDRMatchingModels,
  getTDRSparePartListOfModel,
};
