import api from '../../api';
import { queryListingsOwn } from '../../util/api';
import { updatedEntities, denormalisedEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ManageListingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ManageListingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ManageListingsPage/FETCH_LISTINGS_ERROR';

export const FETCH_LISTING_BULKS_REQUEST = 'app/ManageListingsPage/FETCH_LISTING_BULKS_REQUEST';
export const FETCH_LISTING_BULKS_SUCCESS = 'app/ManageListingsPage/FETCH_LISTING_BULKS_SUCCESS';
export const FETCH_LISTING_BULKS_ERROR = 'app/ManageListingsPage/FETCH_LISTING_BULKS_ERROR';

export const CLEAR_LISTING_BULKS = 'app/ManageListingsPage/CLEAR_LISTING_BULKS';

export const DELETE_LISTING_BULK_REQUEST = 'app/ManageListingsPage/DELETE_LISTING_BULK_REQUEST';
export const DELETE_LISTING_BULK_SUCCESS = 'app/ManageListingsPage/DELETE_LISTING_BULK_SUCCESS';
export const DELETE_LISTING_BULK_ERROR = 'app/ManageListingsPage/DELETE_LISTING_BULK_ERROR';

export const OPEN_LISTING_REQUEST = 'app/ManageListingsPage/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS = 'app/ManageListingsPage/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/ManageListingsPage/OPEN_LISTING_ERROR';

export const CLOSE_LISTING_REQUEST = 'app/ManageListingsPage/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/ManageListingsPage/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/ManageListingsPage/CLOSE_LISTING_ERROR';

export const DELETE_DRAFT_LISTING_REQUEST = 'app/ManageListingsPage/DELETE_DRAFT_LISTING_REQUEST';
export const DELETE_DRAFT_LISTING_SUCCESS = 'app/ManageListingsPage/DELETE_DRAFT_LISTING_SUCCESS';
export const DELETE_DRAFT_LISTING_ERROR = 'app/ManageListingsPage/DELETE_DRAFT_LISTING_ERROR';

export const ADD_OWN_ENTITIES = 'app/ManageListingsPage/ADD_OWN_ENTITIES';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  ownEntities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
  deletingDraftListing: null,
  deletingDraftListingError: null,

  listingBulks: [],
  listingBulksPagination: null,
  fetchBulksError: null,
  fetchBulksInProgress: false,

  deleteBulkInProgress: false,
  deleteBulkError: null,
};

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const updateListingAttributes = (state, listingEntity) => {
  const oldListing = state.ownEntities.ownListing[listingEntity.id.uuid];
  const updatedListing = { ...oldListing, attributes: listingEntity.attributes };
  const ownListingEntities = {
    ...state.ownEntities.ownListing,
    [listingEntity.id.uuid]: updatedListing,
  };
  return {
    ...state,
    ownEntities: { ...state.ownEntities, ownListing: ownListingEntities },
  };
};

const removeListing = (state, listingEntity) => {
  const currentPageResultIds = state.currentPageResultIds.filter(
    id => id.uuid !== listingEntity.id.uuid
  );

  const ownListing = { ...state.ownEntities.ownListing };
  delete ownListing[listingEntity.id.uuid];

  return {
    ...state,
    currentPageResultIds,
    ownEntities: { ...state.ownEntities, ownListing },
  };
};

const manageListingsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case FETCH_LISTING_BULKS_REQUEST:
      return {
        ...state,
        fetchBulksInProgress: true,
        fetchBulksError: null,
      };
    case FETCH_LISTING_BULKS_SUCCESS:
      return {
        ...state,
        fetchBulksInProgress: false,
        listingBulks: [...state.listingBulks, ...payload.items],
        listingBulksPagination: payload.pagination,
      };
    case FETCH_LISTING_BULKS_ERROR:
      return { ...state, fetchBulksInProgress: true, fetchBulksError: payload };

    case CLEAR_LISTING_BULKS:
      return { ...state, listingBulks: [], listingBulksPagination: null };

    case OPEN_LISTING_REQUEST:
      return {
        ...state,
        openingListing: payload.listingId,
        openingListingError: null,
      };
    case OPEN_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        openingListing: null,
      };
    case OPEN_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        openingListing: null,
        openingListingError: {
          listingId: state.openingListing,
          error: payload,
        },
      };
    }

    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: payload.listingId,
        closingListingError: null,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        closingListing: null,
      };
    case CLOSE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: payload,
        },
      };
    }

    case DELETE_DRAFT_LISTING_REQUEST:
      return {
        ...state,
        deletingDraftListing: payload.listingId,
        deletingDraftListingError: null,
      };
    case DELETE_DRAFT_LISTING_SUCCESS:
      return {
        ...removeListing(state, payload.data),
        deletingDraftListing: null,
      };
    case DELETE_DRAFT_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        deletingDraftListing: null,
        deletingDraftListingError: {
          listingId: state.deletingDraftListing,
          error: payload,
        },
      };
    }

    case DELETE_LISTING_BULK_REQUEST:
      return { ...state, deleteBulkInProgress: true, deleteBulkError: null };
    case DELETE_LISTING_BULK_SUCCESS:
      return {
        ...state,
        deleteBulkInProgress: false,
        listingBulks: state.listingBulks.filter(bulk => bulk._id !== payload),
      };
    case DELETE_LISTING_BULK_ERROR:
      return { ...state, deleteBulkInProgress: false, deleteBulkError: payload };

    case ADD_OWN_ENTITIES:
      return merge(state, payload);

    default:
      return state;
  }
};

export default manageListingsPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state.ManageListingsPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'ownListing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const openListingRequest = listingId => ({
  type: OPEN_LISTING_REQUEST,
  payload: { listingId },
});

export const openListingSuccess = response => ({
  type: OPEN_LISTING_SUCCESS,
  payload: response.data,
});

export const openListingError = e => ({
  type: OPEN_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = listingId => ({
  type: CLOSE_LISTING_REQUEST,
  payload: { listingId },
});

export const closeListingSuccess = response => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: response.data,
});

export const closeListingError = e => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const deleteDraftListingRequest = listingId => ({
  type: DELETE_DRAFT_LISTING_REQUEST,
  payload: { listingId },
});

export const deleteDraftListingSuccess = response => ({
  type: DELETE_DRAFT_LISTING_SUCCESS,
  payload: response.data,
});

export const deleteDraftListingError = e => ({
  type: DELETE_DRAFT_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const fetchListingBulksRequest = () => ({
  type: FETCH_LISTING_BULKS_REQUEST,
});

const fetchListingBulksSuccess = (items, pagination) => ({
  type: FETCH_LISTING_BULKS_SUCCESS,
  payload: { items, pagination },
});

const fetchListingBulksError = e => ({
  type: FETCH_LISTING_BULKS_ERROR,
  payload: e,
});

const deleteListingBulkRequest = () => ({
  type: DELETE_LISTING_BULK_REQUEST,
});

const deleteListingBulkSuccess = bulkId => ({
  type: DELETE_LISTING_BULK_SUCCESS,
  payload: bulkId,
});

const deleteListingBulkError = e => ({
  type: DELETE_LISTING_BULK_ERROR,
  payload: e,
});

const clearListingBulks = () => ({
  type: CLEAR_LISTING_BULKS,
});

// Throwing error for new (loadData may need that info)
export const queryOwnListings = queryParams => dispatch => {
  dispatch(queryListingsRequest(queryParams));

  dispatch(clearListingBulks());

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, perPage };

  return queryListingsOwn(params)
    .then(response => {
      dispatch(addOwnEntities(response));
      dispatch(queryListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export const closeListing = (listingId, closeReason) => (dispatch, getState, sdk) => {
  dispatch(closeListingRequest(listingId));

  return sdk.ownListings
    .close({ id: listingId }, { expand: true })
    .then(response => {
      return sdk.ownListings
        .update({
          id: listingId,
          privateData: {
            closeReason,
          },
        })
        .then(() => {
          dispatch(closeListingSuccess(response));
          return response;
        })
        .catch(() => {});
    })
    .catch(e => {
      dispatch(closeListingError(storableError(e)));

      throw e;
    });
};

export const openListing = listingId => (dispatch, getState, sdk) => {
  dispatch(openListingRequest(listingId));

  return sdk.ownListings
    .open({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(openListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(openListingError(storableError(e)));
    });
};

export const deleteListing = listingId => (dispatch, getState, sdk) => {
  dispatch(deleteDraftListingRequest(listingId));

  return sdk.ownListings
    .discardDraft({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(deleteDraftListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(deleteDraftListingError(storableError(e)));
    });
};

export const fetchListingBulks = params => dispatch => {
  dispatch(fetchListingBulksRequest());

  return api.listingBulks
    .getListingBulks(params)
    .then(res => {
      const { items, ...pagination } = res.data;

      dispatch(fetchListingBulksSuccess(items, pagination));
    })
    .catch(e => {
      dispatch(fetchListingBulksError(e));
    });
};

export const deleteListingBulk = bulkId => dispatch => {
  dispatch(deleteListingBulkRequest());

  return api.listingBulks
    .deleteListingBulk(bulkId)
    .then(() => {
      dispatch(deleteListingBulkSuccess(bulkId));
    })
    .catch(e => {
      dispatch(deleteListingBulkError(bulkId));
    });
};

export const loadData = (params, search, config) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return queryOwnListings({
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    pub_isOnMyListingsPage: true,
    include: ['images', 'currentStock'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  });
};
