import httpClient from '../httpClient';

const createListingBulk = (name, items, lang, locale, currency, country) => {
  return httpClient.post('listing-bulks', { name, items, lang, locale, currency, country });
};

const getListingBulks = params => {
  return httpClient.get('listing-bulks', { params });
};

const getListingBulk = bulkId => {
  return httpClient.get(`listing-bulks/${bulkId}`);
};

const getListingBulkItems = (bulkId, params) => {
  return httpClient.get(`listing-bulks/${bulkId}/items`, { params });
};

const publishListingBulk = (bulkId, delivery) => {
  return httpClient.post(`listing-bulks/${bulkId}/publish`, delivery);
};

const deleteListingBulk = bulkId => {
  return httpClient.delete(`listing-bulks/${bulkId}`);
};

const updateListingForItem = (bulkId, bulkItemId, body) => {
  return httpClient.post(`listing-bulks/${bulkId}/items/${bulkItemId}/update-listing`, body);
};

const updateStockForItem = (bulkId, bulkItemId, body) => {
  return httpClient.post(`listing-bulks/${bulkId}/items/${bulkItemId}/update-stock`, body);
};

const publishListingForItem = (bulkId, bulkItemId) => {
  return httpClient.post(`listing-bulks/${bulkId}/items/${bulkItemId}/publish-listing`);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createListingBulk,
  getListingBulks,
  getListingBulkItems,
  getListingBulk,
  publishListingBulk,
  deleteListingBulk,
  updateListingForItem,
  updateStockForItem,
  publishListingForItem,
};
