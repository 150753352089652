import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { priceData } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';

import { AspectRatioWrapper, AvatarSmall, NamedLink, ResponsiveImage } from '../../components';
import { LISTING_INTENT_TYPE } from '../../constants/listing';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    isListView,
  } = props;
  const classes = classNames(rootClassName || css.root, { [css.listView]: isListView }, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const authorDisplayName = author.attributes.profile.publicData?.companyName || authorName;

  const { intentType, location } = publicData || {};

  const isRequesting = intentType === LISTING_INTENT_TYPE.REQUESTING;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const currency = price?.currency || config.currency;
  const { formattedPrice, priceTitle } = priceData(price, currency, intl);

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  return (
    <div className={classes} {...setActivePropsMaybe}>
      <NamedLink name="ListingPage" params={{ id, slug }} className={css.link} />
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      <div className={classNames(css.priceContainer, { [css.priceOverImage]: !isListView })}>
        <div className={css.priceWrapper}>
          {isRequesting ? (
            <FormattedMessage id="General.requesting" />
          ) : (
            <span title={priceTitle}>{formattedPrice}</span>
          )}
        </div>
      </div>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {isListView ? <span className={css.location}>{location?.address}</span> : null}
          {showAuthorInfo ? (
            <NamedLink
              name="ProfilePage"
              params={{ id: author.id.uuid }}
              className={css.authorInfo}
            >
              {isListView && (
                <AvatarSmall user={author} disableProfileLink className={css.avatar} />
              )}
              <FormattedMessage
                id="ListingCard.author"
                values={{ authorName: authorDisplayName }}
              />
            </NamedLink>
          ) : null}
        </div>
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,
  isListView: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
