import React from 'react';
import NamedLink from '../NamedLink/NamedLink';
import ReviewRating from './ReviewRating';
import classNames from 'classnames';

import css from './AverageReviewRating.module.css';

const AverageReviewRating = props => {
  const { user, className, reviewStarClassName } = props;

  const { metadata } = user?.attributes?.profile || {};

  const { averageRating, ratingCount } = metadata || {};

  return averageRating && user?.id ? (
    <NamedLink
      className={classNames(css.root, className)}
      name="ProfilePage"
      params={{ id: user.id.uuid }}
      to={{
        hash: '#reviews',
      }}
    >
      <ReviewRating
        reviewStarClassName={reviewStarClassName}
        rating={Number.parseInt(averageRating)}
      />
      <span>{`(${ratingCount})`}</span>
    </NamedLink>
  ) : null;
};

export default AverageReviewRating;
