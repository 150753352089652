import union from 'lodash/union';
import api from '../api';

/**
 * Parse response to `{[string]: string[]}` (`{[mainOption]: suboption[]}`)
 * @param {object} options object with structure
 * `{[key]: { [key2]: value, ...}, ...}`
 * @returns `{[string]: string[]}`
 */
const parseSuboptions = options =>
  Object.entries(options).reduce((asObject, subcategory) => {
    const [category, subcategories] = subcategory;

    asObject[category] = Object.values(subcategories);

    return asObject;
  }, {});

export const GET_CATEGORY_REQUEST = 'app/dimr/GET_CATEGORY_REQUEST';
export const GET_CATEGORY_SUCCESS = 'app/dimr/GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_ERROR = 'app/dimr/GET_CATEGORY_ERROR';

export const GET_DIMR_ATTRIBUTES_REQUEST = 'app/dimr/GET_DIMR_ATTRIBUTES_REQUEST';
export const GET_DIMR_ATTRIBUTES_SUCCESS = 'app/dimr/GET_DIMR_ATTRIBUTES_SUCCESS';
export const GET_DIMR_ATTRIBUTES_ERROR = 'app/dimr/GET_DIMR_ATTRIBUTES_ERROR';

// ================ Reducer ================ //

const initialState = {
  categories: [],
  getCategoriesInProgress: false,
  getCategoriesError: null,

  dimrAttributes: [],
  getDIMRAttributesInProgress: false,
  getDIMRAttributesError: null,

  subcategories: {},
  itemTypes: {},
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GET_CATEGORY_REQUEST:
      return { ...state, getCategoriesInProgress: true, getCategoriesError: null };
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        getCategoriesInProgress: false,
        ...payload,
      };
    case GET_CATEGORY_ERROR:
      return { ...state, getCategoriesInProgress: false, getCategoriesError: payload };

    case GET_DIMR_ATTRIBUTES_REQUEST:
      return { ...state, getDIMRAttributesInProgress: true, getDIMRAttributesError: null };
    case GET_DIMR_ATTRIBUTES_SUCCESS:
      return { ...state, getDIMRAttributesInProgress: false, dimrAttributes: payload };
    case GET_DIMR_ATTRIBUTES_ERROR:
      return {
        ...state,
        getDIMRAttributesInProgress: false,
        getDIMRAttributesError: payload,
        dimrAttributes: [],
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const getCategoriesRequest = () => ({ type: GET_CATEGORY_REQUEST });
const getCategoriesSuccess = payload => ({ type: GET_CATEGORY_SUCCESS, payload });
const getCategoriesError = payload => ({ type: GET_CATEGORY_ERROR, payload });

const getDIMRAttributesRequest = () => ({ type: GET_DIMR_ATTRIBUTES_REQUEST });
export const getDIMRAttributesSuccess = payload => ({ type: GET_DIMR_ATTRIBUTES_SUCCESS, payload });
const getDIMRAttributesError = payload => ({ type: GET_DIMR_ATTRIBUTES_ERROR, payload });

// ================ Selectors ================ //
/**
 *
 * @param {object} mainOptions This will be the object from DIMR, it is a `{[key]:value[]}` object where the `key` is
 * a category and `value[]` is all of the possible suboptions for that category
 * @param {*} optionMaybe specific category (option)
 * @returns value[]
 */
export const getSuboptionsForOption = (mainOptions, optionMaybe) => {
  if (!optionMaybe || !mainOptions[optionMaybe])
    return Object.values(mainOptions).reduce((all, subcategories) => union(all, subcategories), []);

  return mainOptions[optionMaybe];
};

// ================ Thunks ================ //

export const getCategories = () => (dispatch, getState, sdk) => {
  const { categories } = getState().dimr;

  // Don't fetch if we have already fetched
  if (categories?.length) return;

  dispatch(getCategoriesRequest());

  return api.listings
    .getFilters()
    .then(res => {
      const { categories, subcategories, itemTypes } = res.data;

      dispatch(
        getCategoriesSuccess({
          categories: Object.values(categories),
          subcategories: parseSuboptions(subcategories),
          itemTypes: parseSuboptions(itemTypes),
        })
      );
    })
    .catch(e => {
      dispatch(getCategoriesError(e));
    });
};

export const getDIMRAttributes = articleNumber => dispatch => {
  if (Number.isNaN(+articleNumber)) return;

  dispatch(getDIMRAttributesRequest());

  return api.listings
    .getDIMRAttributes(articleNumber)
    .then(res => {
      dispatch(getDIMRAttributesSuccess(res.data));

      return res;
    })
    .catch(e => {
      console.error(e);
      dispatch(getDIMRAttributesError(e));
    });
};
