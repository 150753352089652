import React from 'react';
import { COUNTRIES_BY_LOCALE, getCurrentLocale } from '../../util/locale';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import { ValidationError } from '..';
import classNames from 'classnames';
import { node, string } from 'prop-types';
import { Field } from 'react-final-form';
import { required } from '../../util/validators';

import css from './FieldCountrySelect.module.css';

const FieldCountrySelect = ({ formAPI, rootClassName, className }) => {
  const locale = getCurrentLocale();
  const intl = useIntl();

  const countries = COUNTRIES_BY_LOCALE[locale];

  if (!countries || countries.length < 2) {
    return null;
  }

  return (
    <div className={classNames(rootClassName || css.root, className)}>
      <label htmlFor="country">
        <FormattedMessage id="FieldCountrySelect.label" />
      </label>
      <div className={css.countries}>
        {countries?.map(country => (
          <FieldRadioButtonComponent
            key={country}
            id={formAPI.id ? `${formAPI.id}.country.${country}` : `country.${country}`}
            name="country"
            label={country}
            value={country}
            validate={required(intl.formatMessage({ id: 'FieldCountrySelect.required' }))}
          />
        ))}
      </div>
    </div>
  );
};

const FieldRadioButtonComponent = props => {
  const { rootClassName, className, id, label, ...rest } = props;

  const radioButtonProps = {
    id,
    className: css.input,
    component: 'input',
    type: 'radio',
    ...rest,
  };

  const classes = classNames(rootClassName || css.fieldRoot, className);

  return (
    <div className={classes}>
      <Field {...radioButtonProps}>
        {({ input, meta }) => {
          const hasError = !!(meta.touched && meta.invalid && meta.error);
          const fieldMeta = { touched: hasError, error: meta.error };

          return (
            <>
              <label htmlFor={id} className={css.label}>
                <span className={css.radioButtonWrapper}>
                  <input {...input} id={id} />
                </span>
                <span className={css.text}>{label}</span>
              </label>
              <ValidationError fieldMeta={fieldMeta} />
            </>
          );
        }}
      </Field>
    </div>
  );
};

FieldRadioButtonComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  checkedClassName: null,
  label: null,
};

FieldRadioButtonComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  checkedClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several RadioButtones to an array of selected values
  name: string.isRequired,

  // RadioButton needs a value that is passed forward when user checks the RadioButton
  value: string.isRequired,
};

export default FieldCountrySelect;
