import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeInterfaceLang } from '../ducks/ui.duck';

const useInterfaceLang = () => {
  const dispatch = useDispatch();
  const interfaceLang = useSelector(({ ui: { interfaceLang } }) => interfaceLang);

  const changeLanguage = useCallback(lang => dispatch(changeInterfaceLang(lang)), [dispatch]);

  return { interfaceLang, changeLanguage };
};

export default useInterfaceLang;
