/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { CURRENCIES_BY_LOCALE, getCurrentLocale } from '../../util/locale';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import { IconInfo, ValidationError } from '..';
import classNames from 'classnames';
import { node, string } from 'prop-types';
import { Field } from 'react-final-form';
import { required } from '../../util/validators';
import { Tooltip } from 'react-tooltip';

import css from './FieldCurrencySelect.module.css';

const FieldCurrencySelect = ({ formAPI, rootClassName, className }) => {
  const locale = getCurrentLocale();
  const intl = useIntl();

  const currencies = CURRENCIES_BY_LOCALE[locale];

  if (!currencies || currencies.length < 2) {
    return null;
  }

  return (
    <div className={classNames(rootClassName || css.root, className)}>
      <label htmlFor="currency">
        <FormattedMessage id="FieldCurrencySelect.label" />
        <a data-tooltip-id="my-tooltip">
          <IconInfo />
        </a>
        <Tooltip
          id="my-tooltip"
          place="right-start"
          content={intl.formatMessage({ id: 'FieldCurrencySelect.tooltip' })}
          className={css.tooltip}
          clickable
        />
      </label>
      <div className={css.currencies}>
        {currencies?.map(currency => (
          <FieldRadioButtonComponent
            key={currency.code}
            id={
              formAPI.id ? `${formAPI.id}.currency.${currency.code}` : `currency.${currency.code}`
            }
            name="currency"
            label={currency.label}
            value={currency.code}
            validate={required(intl.formatMessage({ id: 'FieldCurrencySelect.required' }))}
          />
        ))}
      </div>
    </div>
  );
};

const FieldRadioButtonComponent = props => {
  const { rootClassName, className, id, label, ...rest } = props;

  const radioButtonProps = {
    id,
    className: css.input,
    component: 'input',
    type: 'radio',
    ...rest,
  };

  const classes = classNames(rootClassName || css.fieldRoot, className);

  return (
    <div className={classes}>
      <Field {...radioButtonProps}>
        {({ input, meta }) => {
          const hasError = !!(meta.touched && meta.invalid && meta.error);
          const fieldMeta = { touched: hasError, error: meta.error };

          return (
            <>
              <label htmlFor={id} className={css.label}>
                <span className={css.radioButtonWrapper}>
                  <input {...input} id={id} />
                </span>
                <span className={css.text}>{label}</span>
              </label>
              <ValidationError fieldMeta={fieldMeta} />
            </>
          );
        }}
      </Field>
    </div>
  );
};

FieldRadioButtonComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  checkedClassName: null,
  label: null,
};

FieldRadioButtonComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  checkedClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several RadioButtones to an array of selected values
  name: string.isRequired,

  // RadioButton needs a value that is passed forward when user checks the RadioButton
  value: string.isRequired,
};

export default FieldCurrencySelect;
