export const AuthEventTypes = {
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAIL: 'REFRESH_TOKEN_FAIL',
  LOGOUT: 'LOGOUT',
};

/* eslint-disable import/no-anonymous-default-export */
export default {
  listeners: {},
  addEventListener(event, callback) {
    if (!this.listeners[event]) this.listeners[event] = [];
    this.listeners[event].push(callback);

    return () => {
      this.removeEventListener(event, callback);
    };
  },
  removeEventListener(event, callback) {
    this.listeners[event] = this.listeners[event].filter(cb => cb !== callback);
  },
  broadcastEvent(event, payload) {
    if (this.listeners[event]) this.listeners[event].forEach(cb => cb({ payload, event }));
  },
  addMultiEventListener(events, callback) {
    events.forEach(event => {
      if (!this.listeners[event]) this.listeners[event] = [];
      this.listeners[event].push(callback);
    });
  },
  removeMultiEventListener(events, callback) {
    events.forEach(event => {
      this.listeners[event] = this.listeners[event].filter(cb => cb !== callback);
    });
  },
};
