import api from '../../api';
import RefreshTokenService from '../../services/RefreshTokenService';
import { getCurrentLocale } from '../../util/locale';

const MARK_TAB_UPDATED = 'app/ListingBulkUploadPage/MARK_TAB_UPDATED';
const CLEAR_UPDATED_TAB = 'app/ListingBulkUploadPage/CLEAR_UPDATED_TAB';

const FETCH_LISTING_BULK_REQUEST = 'app/ListingBulkUploadPage/FETCH_LISTING_BULK_REQUEST';
const FETCH_LISTING_BULK_SUCCESS = 'app/ListingBulkUploadPage/FETCH_LISTING_BULK_SUCCESS';
const FETCH_LISTING_BULK_ERROR = 'app/ListingBulkUploadPage/FETCH_LISTING_BULK_ERROR';

const FETCH_LISTING_BULK_ITEMS_REQUEST =
  'app/ListingBulkUploadPage/FETCH_LISTING_BULK_ITEMS_REQUEST';
const FETCH_LISTING_BULK_ITEMS_SUCCESS =
  'app/ListingBulkUploadPage/FETCH_LISTING_BULK_ITEMS_SUCCESS';
const FETCH_LISTING_BULK_ITEMS_ERROR = 'app/ListingBulkUploadPage/FETCH_LISTING_BULK_ITEMS_ERROR';

const UPDATE_LISTING_BULK_REQUEST = 'app/ListingBulkUploadPage/UPDATE_LISTING_BULK_REQUEST';
const UPDATE_LISTING_BULK_SUCCESS = 'app/ListingBulkUploadPage/UPDATE_LISTING_BULK_SUCCESS';
const UPDATE_LISTING_BULK_ERROR = 'app/ListingBulkUploadPage/UPDATE_LISTING_BULK_ERROR';

const CLEAR_WIZARD = 'app/ListingBulkUploadPage/CLEAR_WIZARD';

// ================ Reducer ================ //

const initialState = {
  updatedTab: null,
  updateInProgress: false,
  updateBulkError: null,

  fetchInProgress: false,
  fetchError: null,
  listingBulk: null,

  listingBulkItems: [],
  fetchBulkItemsInProgress: false,
  fetchBulksItemsError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_WIZARD:
      return { ...initialState };

    case MARK_TAB_UPDATED:
      return { ...state, updatedTab: payload };
    case CLEAR_UPDATED_TAB:
      return { ...state, updatedTab: null, updateBulkError: null };

    case FETCH_LISTING_BULK_REQUEST:
      return { ...state, fetchInProgress: true, fetchError: null };
    case FETCH_LISTING_BULK_SUCCESS:
      return { ...state, fetchInProgress: false, listingBulk: payload };
    case FETCH_LISTING_BULK_ERROR:
      return { ...state, fetchInProgress: false, fetchError: payload };

    case FETCH_LISTING_BULK_ITEMS_REQUEST:
      return { ...state, fetchBulkItemsInProgress: true, fetchBulksItemsError: null };
    case FETCH_LISTING_BULK_ITEMS_SUCCESS:
      return {
        ...state,
        fetchBulkItemsInProgress: false,
        listingBulkItems: payload.items,
        listingBulkItemsPagination: payload.pagination,
      };
    case FETCH_LISTING_BULK_ITEMS_ERROR:
      return { ...state, fetchBulkItemsInProgress: false, fetchBulksItemsError: payload };

    case UPDATE_LISTING_BULK_REQUEST:
      return { ...state, updateInProgress: true, updateBulkError: null };
    case UPDATE_LISTING_BULK_SUCCESS:
      return { ...state, updateInProgress: false, listingBulk: payload };
    case UPDATE_LISTING_BULK_ERROR:
      return { ...state, updateInProgress: false, updateBulkError: payload };

    default:
      return state;
  }
}

const markTabUpdated = tab => ({
  type: MARK_TAB_UPDATED,
  payload: tab,
});

const clearUpdatedTab = () => ({
  type: CLEAR_UPDATED_TAB,
});

const clearWizard = () => ({
  type: CLEAR_WIZARD,
});

const fetchListingBulkRequest = () => ({ type: FETCH_LISTING_BULK_REQUEST });
const fetchListingBulkSuccess = payload => ({ type: FETCH_LISTING_BULK_SUCCESS, payload });
const fetchListingBulkError = error => ({ type: FETCH_LISTING_BULK_ERROR, payload: error });

const fetchListingBulkItemsRequest = () => ({ type: FETCH_LISTING_BULK_ITEMS_REQUEST });
const fetchListingBulkItemsSuccess = (items, pagination) => ({
  type: FETCH_LISTING_BULK_ITEMS_SUCCESS,
  payload: { items, pagination },
});
const fetchListingBulkItemsError = error => ({
  type: FETCH_LISTING_BULK_ITEMS_ERROR,
  payload: error,
});

const updateListingBulkRequest = () => ({ type: UPDATE_LISTING_BULK_REQUEST });
const updateListingBulkSuccess = payload => ({ type: UPDATE_LISTING_BULK_SUCCESS, payload });
const updateListingBulkError = error => ({ type: UPDATE_LISTING_BULK_ERROR, payload: error });

export const fetchListingBulk = bulkId => dispatch => {
  dispatch(fetchListingBulkRequest());

  return api.listingBulks
    .getListingBulk(bulkId)
    .then(res => {
      dispatch(fetchListingBulkSuccess(res.data));
    })
    .catch(e => {
      dispatch(fetchListingBulkError(e));
    });
};

export const fetchListingBulkItems = params => (dispatch, getState) => {
  dispatch(fetchListingBulkItemsRequest());

  const { listingBulk } = getState().ListingBulkUploadPage;

  return api.listingBulks
    .getListingBulkItems(listingBulk._id, params)
    .then(res => {
      const { items, ...pagination } = res.data;

      dispatch(fetchListingBulkItemsSuccess(items, pagination));
    })
    .catch(e => {
      dispatch(fetchListingBulkItemsError(e));
    });
};

export const createListingBulk = (tab, name, items, lang, currency, country) => async (
  dispatch,
  getState
) => {
  await RefreshTokenService.refreshToken().catch(() => {});

  const locale = getCurrentLocale();

  dispatch(updateListingBulkRequest());

  return api.listingBulks
    .createListingBulk(name, items, lang, locale, currency, country)
    .then(res => {
      dispatch(updateListingBulkSuccess(res.data));

      dispatch(markTabUpdated(tab));

      return res;
    })
    .catch(e => {
      dispatch(updateListingBulkError(e));
    });
};

export const publishListingBulk = (tab, bulkId, delivery) => async dispatch => {
  await RefreshTokenService.refreshToken().catch(() => {});

  dispatch(updateListingBulkRequest());

  const { geolocation, ...rest } = delivery;

  const deliveryUpdates = {
    ...rest,
    geolocation: geolocation ? { lat: geolocation.lat, lng: geolocation.lng } : undefined,
  };

  return api.listingBulks
    .publishListingBulk(bulkId, deliveryUpdates)
    .then(res => {
      dispatch(updateListingBulkSuccess(res?.data));

      dispatch(markTabUpdated(tab));

      return res;
    })
    .catch(e => {
      dispatch(updateListingBulkError(e));
    });
};

// loadData is run for each tab of the wizard
export const loadData = params => (dispatch, getState) => {
  dispatch(clearUpdatedTab());

  const { id } = params;

  const { listingBulk } = getState().ListingBulkUploadPage;

  if (id !== listingBulk?._id) {
    dispatch(clearWizard());
  }

  if (!id || id === '0') {
    return Promise.resolve();
  }

  return dispatch(fetchListingBulk(id));
};
