import stHttpClient from '../api/stHttpClient';
import appSettings from '../config/settings';
import bus, { AuthEventTypes } from '../modules/bus';
import { getTokenStore } from '../util/tokenStore';

/* eslint-disable import/no-anonymous-default-export */
export default {
  refreshingAtm: false,
  triedRefresh: false,
  checkRefreshingTreshold: 10000,
  async refreshToken() {
    this.refreshingAtm = true;

    try {
      const tokenStore = getTokenStore();

      const token = tokenStore.getToken();

      const tokenScope = token?.scope;

      if (!token || tokenScope === 'public-read') {
        throw Error('invalid st token scope');
      }

      const bodyData = new URLSearchParams();

      bodyData.append('client_id', appSettings.sdk.clientId);
      bodyData.append('grant_type', 'refresh_token');
      bodyData.append('scope', 'user');
      bodyData.append('refresh_token', token.refresh_token);

      const { data } = await stHttpClient.post('auth/token', bodyData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      tokenStore.setToken(data);

      bus.broadcastEvent(AuthEventTypes.REFRESH_TOKEN_SUCCESS);
    } catch (err) {
      bus.broadcastEvent(AuthEventTypes.REFRESH_TOKEN_FAIL);

      throw err;
    } finally {
      this.refreshingAtm = false;
      this.triedRefresh = true;

      setTimeout(() => {
        this.triedRefresh = false;
      }, 10000);
    }
  },
  reinit() {
    this.refreshingAtm = false;
  },
};
