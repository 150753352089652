import React from 'react';
import { IntlProvider } from 'react-intl';
import useTranslations from './hooks/useTranslations';
import { useEffect } from 'react';
import { LANGUAGE_COOKIE_KEY } from './util/locale';
import Cookies from 'js-cookie';

const TranslationsWrapper = props => {
  const { hostedTranslations, children } = props;

  const { localeMessages, interfaceLang, changeLanguage } = useTranslations(hostedTranslations);

  useEffect(() => {
    const cookieLang = Cookies.get(LANGUAGE_COOKIE_KEY);

    if (!cookieLang) return;

    changeLanguage(cookieLang);
  }, [changeLanguage]);

  return (
    <IntlProvider locale={interfaceLang} messages={localeMessages} textComponent="span">
      {children}
    </IntlProvider>
  );
};

export default TranslationsWrapper;
