export const LISTING_INTENT_TYPE = {
  SELLING: 'selling',
  REQUESTING: 'requesting',
};

export const LISTING_GROUP = {
  MACHINE: 'machine',
  SPARE_PART: 'spare-part',
};

export const LISTING_CONDITION = {
  GRADE_A: 'GRADE_A',
  GRADE_B: 'GRADE_B',
  GRADE_C: 'GRADE_C',
  GRADE_D: 'GRADE_D', // US only
  NO_GRADE: 'NO_GRADE',
};

export const LISTING_INPUT_METHOD = {
  MANUAL: 'MANUAL',
  ARTICLE_NUMBER: 'ARTICLE_NUMBER',
};

/**
 * Used in the LCW because the first step
 * requires a `title` to be passed
 * in order to create a listing
 */
export const DUMMY_LISTING_TITLE = 'HUSQVARNA_RESPARE_DUMMY_TITLE';
